import React, { useState } from "react";
import { connect as connectFela } from "react-fela";

import P from "components/typography/P";
import colors from "theme/colors";
import fluidFonts from "utilities/fluidFonts";
import { fontFamilyMonospace } from "gatsby-theme-fela/fonts";

const TextInput = ({
  rules,
  styles,
  title,
  name,
  value,
  error,
  onChange,
  required,
  email,
  tel,
}) => {
  const [focused, setFocus] = useState(false);
  const id = "form_" + name + "_" + Math.floor(Math.random() * 100 + 1);

  const inputType = email ? "email" : tel ? "tel" : "text";

  return (
    <div className={styles.container}>
      <label
        htmlFor={id}
        className={
          (focused || value ? styles.focused : "") + " " + styles.label
        }
      >
        {title}
      </label>

      <input
        type={inputType}
        onChange={onChange}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
        name={name}
        required={required}
        id={id}
        value={value}
        className={styles.input}
      />

      {error && (
        <P extend={{ styles: rules.error }} version={"xsmall"}>
          {error}
        </P>
      )}
    </div>
  );
};

/*
 */
const styles = (props) => ({
  container: {
    height: 50,
    paddingBottom: 25,
    position: "relative",
  },
  label: {
    ...fluidFonts({ fontSize: [12, 16], lineHeight: [14, 22] }),
    fontFamily: fontFamilyMonospace,
    fontWeight: 300,
    position: "absolute",
    top: 17,
    whiteSpace: "break-spaces",
    transition: "transform 0.02s, font-size 0.02s",
  },
  focused: {
    transform: "translateY(-20px)",
    fontFamily: fontFamilyMonospace,
    ...fluidFonts({ fontSize: [10, 10], lineHeight: [14, 14] }),

    extend: {
      condition: props.error,
      style: {
        color: colors.colorHuman900,
      },
    },
  },
  error: {
    bottom: 0,
    top: 53,
    color: colors.colorHuman900,
    marginBottom: 0,
    position: "absolute",
  },
  input: {
    ...fluidFonts({ fontSize: [12, 16], lineHeight: [14, 22] }),
    fontFamily: fontFamilyMonospace,
    fontWeight: 300,
    paddingBottom: 6,
    outline: "none",

    onFocus: {
      color: colors.colorConsole300,
      outline: "none",
      "::-moz-focus-inner": {
        border: 0,
      },
    },

    "::focus-visible": {
      color: colors.colorConsole300,
      outline: "none",
      "::-moz-focus-inner": {
        border: 0,
      },
    },

    backgroundColor: "transparent",
    bordeSize: "border-box",
    borderBottom: "1px solid ",
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",
    bottom: 25,
    display: "block",
    height: 30,
    position: "absolute",
    width: "100%",
  },
});

export default connectFela(styles)(TextInput);
