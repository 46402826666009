/**
 * @fileOverview A element styled as Button
 * @name Button.js
 */
import React from "react"
import { connect as connectFela } from "react-fela"

import fluidDimensions from "utilities/fluidDimensions"
import colors from "theme/colors"

/**
 * P
 *
 * @param {object} styles Fela styles
 * @param {array} children Children components, usually just text
 *
 * @return {jsx} styled A
 */
const prevButton = ({styles, rules, children, href, onClick}) => {
  return <a alt={"Prev"} href={href} className={styles.styles} onClick={onClick}>
    <svg width="60" height="60" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="40" cy="40" r="39.5" transform="rotate(90 40 40)" stroke="#302739"/>
      <path d="M21.5 39.134C20.8333 39.5189 20.8333 40.4811 21.5 40.866L32 46.9282C32.6667 47.3131 33.5 46.832 33.5 46.0622L33.5 33.9378C33.5 33.168 32.6667 32.6869 32 33.0718L21.5 39.134Z" fill="#302739"/>
    </svg>
  </a>
}

/* Styles */
const styles = (props) => ({
  cursor: 'pointer',
  ...fluidDimensions({width: [36, 60], height: [36, 60]}),
  '& svg': {
    transition: 'fill 0.2s ease',
    width: '100%',
    fill: 'transparent',

    onHover: {
      fill: colors.colorConsole500,
    }
  },
  onHover:{
    transition: 'fill 0.2s ease',
    stroke: colors.colorConsole500,

    '& path': {
      fill: '#FFFFFF',
    },
    '& line': {
      stroke: '#FFFFFF'
    },
  }
})

/* Component */
export default connectFela({styles})(prevButton)
