import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { connect as connectFela } from "react-fela";

import BlockContent from "@sanity/block-content-to-react";
import Button from "components/Button";
import Container from "components/container";
import FullWidthSlider from "components/FullWidthSlider";
import Layout from "components/layout";
import ResourcePageHeader from "components/ResourcePageHeader";
import colors from "theme/colors";
import fluidFonts from "utilities/fluidFonts";
import fluidValues from "utilities/fluidValues";
import { extractFilename } from "../commands/playbookUrl";

import P from "components/typography/P";
import H3 from "components/typography/H3";
import Ul from "components/typography/UL";
import { serializers as linkSerializers } from "components/BaseLink.js";
import ContentImage from "components/ContentImage";
import Tale from "components/Tale";

const serializers = ({ styles, rules }) => {
  return {
    list: (props) => <Ul extend={{ styles: rules.list }}>{props.children}</Ul>,
    listItem: (props) => (
      <P tag={"li"} version="list">
        {props.children}
      </P>
    ),
    types: {
      contentImage: (props) => <ContentImage {...props} />, //ref
      block: (props) => {
        const style = props.node.style;

        if (style === "xlarge") {
          return (
            <P extend={{ styles: rules.xlarge }} version="xlarge">
              {props.children}
            </P>
          );
        }

        if (style === "title") {
          return (
            <H3 extend={{ container: rules.paragraphTitle }}>
              {props.children}
            </H3>
          );
        }

        if (style === "large") {
          return <P version="large">{props.children}</P>;
        }

        if (style === "quote") {
          return (
            <P version="strapline" extend={{ styles: rules.strapline }}>
              {props.children}
            </P>
          );
        }

        if (style === "normal") {
          return (
            <P version="body" extend={{ styles: rules.normal }}>
              {props.children}
            </P>
          );
        }
        return BlockContent.defaultSerializers.types.block(props);
      },
    },
    marks: {
      ...linkSerializers(styles),
      sup: (props) => <sup>{props.children}</sup>,
      highlight: (props) => (
        <span className={styles.highlight}>{props.children}</span>
      ),
      colored: (props) => (
        <span className={styles.colored}>{props.children}</span>
      ),
    },
  };
};

const TinyTalesPage = ({ path, pageContext, data, rules, styles }) => {
  const {
    title,
    meta,
    slug,
    intro,
    _rawExcerpt,
    slider,
    buttonlabel,
    tinyTale,
    tales,
  } = data.sanityTinyTalesPage;
  const filename = extractFilename(tinyTale);
  return (
    <Layout
      pageColor={colors.colorHuman200}
      linkColor={colors.colorHuman500}
      title={title}
      slug={{ current: path }}
      meta={meta || {}}
      settings={data.settings}
      jobs={data.jobs}
      country={pageContext.country}
    >
      <ResourcePageHeader
        title={title}
        intro={intro}
        extend={{ introContainer: rules.header }}
        pageColor={colors.colorHuman200}
      />

      <Container
        tag={"section"}
        withContentArea3
        extend={{ styles: rules.sliderContentContainer }}
      >
        <BlockContent
          className={styles.sliderContent}
          blocks={_rawExcerpt}
          serializers={serializers({ rules, styles })}
        />
      </Container>

      <Container tag={"section"} fullscreen extend={{ styles: rules.slider }}>
        <FullWidthSlider images={slider} />
      </Container>

      <Container
        tag={"section"}
        withContentArea3
        extend={{ styles: rules.buttonContentContainer }}
      >
        <div className={styles.lineDiv}></div>
        <Button
          download={filename}
          extend={{ styles: rules.downloadButton }}
          to={tinyTale}
        >
          {buttonlabel}
        </Button>
      </Container>

      {tales.map((tale, index) => (
        <Tale index={index} tale={tale} />
      ))}
    </Layout>
  );
};

const leftLine = {
  ...fluidValues({ marginLeft: [-24, -110], marginRight: [24, 110] }),
  backgroundColor: colors.colorConsole500,
  bottom: 0,
  content: '""',
  display: "inline-block",
  position: "absolute",
  width: 1,
};

const styles = () => ({
  strapline: {
    ...fluidFonts({ fontSize: [18, 28], lineHeight: [24, 36] }),
  },
  header: {
    paddingBottom: "0 !important",
  },
  line: {
    display: "block",
    margin: "0 auto",
    width: 1,
    ...fluidValues({
      height: [90, 200],
      marginTop: [40, 94],
      marginBottom: [24, 60],
    }),
    backgroundColor: "black",
  },
  lineDiv: {
    tabletPortraitAndUp: {
      position: "absolute",
      ...fluidValues({
        left: [-4, -30],
      }),
      borderBottom: "1px solid black",
      width: "calc(24% + 30px)",
      bottom: 0,
    },
  },
  buttonContentContainer: {
    width: "auto",
    zIndex: 2,
    marginTop: 0,
    ...fluidValues({
      marginBottom: [48, 162],
      marginLeft: [23, 291],
      paddingLeft: [24, 109],
      paddingTop: [32, 124],
    }),

    phoneOnly: {
      marginLeft: "0 !important",
    },

    tabletPortraitAndUp: {
      onBefore: {
        ...leftLine,
        height: "100%",
      },
    },
  },
  sliderContentContainer: {
    "> div": {
      paddingBottom: 60,
      maxWidth: "720px !important",
    },
    width: "auto",
    zIndex: 2,
    ...fluidValues({
      marginLeft: [23, 291],
      marginTop: [64, 100],
      paddingLeft: [24, 109],
    }),

    onBefore: {
      ...leftLine,
      height: "100%",
    },
  },
  slider: {
    zIndex: 2
  },
  letMeKnow: {
    marginTop: 27,
  },
  downloadButton: {
    backgroundColor: "white",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    zIndex: 200,
    phoneOnly: {
      width: "100% !important",
      paddingLeft: 0,
      paddingRight: 0,
    },
    ...fluidValues({
      width: [145, 400],
    }),
    tabletPortraitAndUp: {
      position: "absolute",
      left: "24%",
      top: "60%",
    },
  },
  comingSoonContainer: {
    ...fluidValues({
      marginTop: [60, 120],
      marginBottom: [60, 200],
    }),
  },
  comingSoonCoverContainer: {
    flex: "1 1 100%",
    tabletPortraitAndUp: {
      flex: "1 1 50%",
    },
  },
  comingSoonContentContainer: {
    marginTop: 24,
    flex: "1 1 100%",
    boxSizing: "border-box",
    display: "flex",
    flexFlow: "column wrap",
    alignItems: "left",
    justifyContent: "center",
    paddingLeft: 0,
    tabletPortraitAndUp: {
      flex: "1 1 50%",
      ...fluidValues({
        paddingLeft: [0, 120],
      }),
    },
  },
  paragraphTitle: {
    marginBottom: "0 !important",
  },
  straplineTitle: {
    ...fluidFonts({ fontSize: [18, 28], lineHeight: [24, 36] }),
    onAfter: {
      backgroundColor: colors.colorConsole500,
      content: '""',
      display: "block",
      height: 6,
      minWidth: 24,
      maxWidth: 32,
      width: "19%",
      position: "relative",
      zIndex: 1,
      ...fluidValues({ marginTop: [12, 35] }),
    },
  },
});

TinyTalesPage.defaultProps = {
  rules: {},
  styles: {},
};

TinyTalesPage.propTypes = {
  data: PropTypes.object.isRequired,
  rules: PropTypes.object,
  styles: PropTypes.object,
};

export default connectFela(styles)(TinyTalesPage);

export const query = graphql`
  query ($country: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
      ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
      edges {
        node {
          ...Jobs
        }
      }
    }

    sanityTinyTalesPage {
      countrySet
      title
      meta {
        title
        description
        other {
          property
          content
        }
      }
      slug {
        current
      }
      intro
      _rawExcerpt
      slider {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 1440)
        }
      }
      buttonlabel
      tinyTale {
        asset {
          url
          localFile {
            publicURL
            name
            internal {
              contentDigest
            }
          }
          originalFilename
        }
      }
      comingSoonCover {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 1440)
        }
      }
      issueNumber
      issueDate
      tales {
        ...taleFrag
      }
    }
  }
`;
