import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { connect as connectFela } from "react-fela";
import { combineRules } from "fela";

import Button from "components/Button";
import Container from "components/container";
import colors from "theme/colors";
import fluidValues from "utilities/fluidValues";
import fluidFonts from "utilities/fluidFonts";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import { extractFilename } from "../commands/playbookUrl";

import P from "components/typography/P";
import H3 from "components/typography/H3";

import Modal from "components/Modal";
import SignupForm from "components/SignupForm";

const Tale = ({ rules, styles, tale }) => {
  const [interestModal, setInterestModal] = useState(false);

  const filename = extractFilename(tale.book);
  return (
    <Container
      tag={"section"}
      flex
      withContentArea3
      extend={{ styles: rules.comingSoonContainer }}
    >
      <div className={styles.comingSoonCoverContainer}>
        {tale.cover?.asset && (
          <Img
            objectFit={"contain"}
            className={styles.comingSoonCover}
            image={tale.cover.asset.gatsbyImageData}
            alt={""}
          />
        )}
      </div>

      <div className={styles.comingSoonContentContainer}>
        <H3 noMargin>Issue {tale.issueNumber}</H3>
        <P extend={{ styles: rules.straplineTitle }} version="strapline">
          {tale.issueDate}
        </P>
        <P version="info">{tale.description}</P>

        {tale.book && (
          <Button
            download={filename}
            extend={{ styles: rules.letMeKnow }}
            to={tale.book}
          >
            DOWNLOAD ISSUE {tale.issueNumber}
          </Button>
        )}

        {!tale.book && (
          <Button
            onClick={(e) => {
              e.preventDefault();
              setInterestModal(true);
            }}
            extend={{ styles: rules.letMeKnow }}
            to={""}
          >
            LET ME KNOW
          </Button>
        )}

        {!tale.book && (
          <Modal opened={interestModal} close={() => setInterestModal(false)}>
            <SignupForm
              title={"Let me know"}
              color={"#000000"}
              thankYouMessage={
                "Thanks for signing up for our next issue of Tiny Tales! We'll email you as soon as it's ready."
              }
              cmListID={"f5e1c02d825c06612196b3f973c7e933"}
            />
          </Modal>
        )}
      </div>
    </Container>
  );
};

const styles = ({ index }) => ({
  comingSoonContainer: {
    ...fluidValues({
      marginTop: [48, 90],
      marginBottom: [48, 90],
    }),
  },
  comingSoonCoverContainer: {
    flex: "1 1 100%",
    maxHeight: 723,
    position: "relative",
    maxWidth: 515,
    border: `1px solid ${colors.colorConsole500}`,
    tabletPortraitAndUp: {
      flex: "1 1 50%",
    },
    extend: [
      {
        condition: (index + 1) % 2 === 0,
        style: {
          tabletPortraitAndUp: {
            order: 2,
          },
        },
      },
    ],
    onAfter: {
      content: "''",
      backgroundColor: colors.colorConsole500,
      display: "block",
      position: "absolute",
      width: "90%",
      marginLeft: "5%",
      left: 0,
      ...fluidValues({
        bottom: [-7, -13],
        height: [6, 12],
      }),
    },
  },
  comingSoonCover: {
    height: "100%",
  },
  comingSoonContentContainer: {
    marginTop: 24,
    flex: "1 1 100%",
    boxSizing: "border-box",
    display: "flex",
    flexFlow: "column wrap",
    alignItems: "left",
    justifyContent: "center",
    paddingLeft: 0,

    extend: [
      {
        condition: index % 2 === 0,
        style: {
          tabletPortraitAndUp: {
            flex: "1 1 50%",
            ...fluidValues({
              paddingLeft: [0, 120],
            }),
          },
        },
      },
      {
        condition: (index + 1) % 2 === 0,
        style: {
          tabletPortraitAndUp: {
            flex: "1 1 50%",
            ...fluidValues({
              paddingRight: [0, 120],
            }),
          },
        },
      },
    ],
  },
  letMeKnow: {
    marginTop: 27,
  },
  straplineTitle: {
    ...fluidFonts({ fontSize: [18, 28], lineHeight: [24, 36] }),
    onAfter: {
      backgroundColor: colors.colorConsole500,
      content: '""',
      display: "block",
      height: 6,
      minWidth: 24,
      maxWidth: 32,
      width: "19%",
      position: "relative",
      zIndex: 1,
      ...fluidValues({ marginTop: [12, 35] }),
    },
  },
});

export default connectFela(styles)(Tale);

export const taleFrag = graphql`
  fragment taleFrag on SanityTale {
    issueDate
    issueNumber
    description
    book {
      asset {
        url
        localFile {
          publicURL
          name
          internal {
            contentDigest
          }
        }
        originalFilename
      }
    }
    cover {
      asset {
        gatsbyImageData(layout: CONSTRAINED, width: 1440)
      }
    }
  }
`;
