import React, { useState, useCallback } from 'react'
import { connect as connectFela } from "react-fela"
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { useTransition, animated } from 'react-spring'
import { useSwipeable } from 'react-swipeable'

import Container from "components/container"
import fluidDimensions from "utilities/fluidDimensions"
import fluidValues from "utilities/fluidValues"
import NextButton from "components/NextButton"
import PrevButton from "components/PrevButton"
import colors from "theme/colors"

/**
 * Image slider
 *
 * @param {object} styles Fela styles
 * @param {array} children Children components, usually just text
 *className={styles.image}
 * @return {jsx}
 */
const FullWidthSlider = ({rules, images, styles}) => {
  const [current, set] = useState(0)
  const showPrevious = useCallback(() => current > 0 && set(current => (current - 1)), [current])
  const showNext = useCallback(() => current < (images.length - 1) && set(current => (current + 1)), [current])
  const transitions = useTransition(current, {
    from:  { opacity: 0, transform: 'translate3d(0,100%,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0%,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-50%,0)' },
  })
  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => showPrevious(),
    onSwipedLeft: (eventData) => showNext(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  return <Container flex noPadding fullscreen>
    <div className={styles.pageDots}>
      {images && images.length> 1 && images.map((image, index) =>
        <div key={index + "image"} className={styles.dot + (current == index ? " current" : "" )} onClick={() => set(index) }></div>
      )}
    </div>
    <div className={styles.container}>
      <div className={styles.sliderContainer} {...swipeHandlers}>
        {images.length > 0 && transitions((props, item) => {
          return <animated.div className={styles.imageContainer} style={props}>
            <Img className={styles.image} image={images[item].asset.gatsbyImageData} alt={""} />
          </animated.div>
        })}
      </div>
      <div className={styles.buttonContainer}>
        {images.length > 0 && current > 0 && <PrevButton onClick={showPrevious} extend={{styles: rules.prevButton}}/>}
        {images.length > 0 && current < (images.length - 1) && <NextButton onClick={showNext} extend={{styles: rules.nextButton}}/>}
      </div>
    </div>
  </Container>
}

/* styles */
const styles = () => ({
  container: {
    flex: '1 1 auto',
    textAlign: 'left',
    ...fluidDimensions({height: [308, 868]}),
    ...fluidValues({marginleft: [-12, -16], marginright: [-12, -16]}),
  },
  sliderContainer: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',

    phoneOnly: {
      width: '100%',
    }
  },
  imageContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%'
  },
  pageDots: {
    ...fluidDimensions({width: [20, 80]}),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    phoneOnly: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginRight: 20,
      marginTop: 20,
      maxWidth: '100%',
      order: 2,
      width: '100%',
    }
  },
  dot: {
    backgroundColor: colors.colorCanvas800,
    borderRadius: '50%',
    cursor: 'pointer',
    height: 8.7,
    marginBottom: 20,
    width: 8.7,

    phoneOnly: {
      marginLeft: 10,
      marginBottom: 0,
    },

    '&.current': {
      backgroundColor: colors.colorConsole500,
    }
  },
  buttonContainer: {
    display: 'flex',

    phoneOnly: {
      display: 'none'
    }
  },
  prevButton: {
    ...fluidValues({marginTop: [24, 40], marginRight: [12, 24]}),
  },
  nextButton: {
    ...fluidValues({marginTop: [24, 40]}),
  }
})

/* component */
export default  connectFela(styles)(FullWidthSlider)
