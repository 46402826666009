/**
 * @fileOverview A element styled as Button
 * @name Button.js
 */
import React from "react"
import { connect as connectFela } from "react-fela"

import fluidDimensions from "utilities/fluidDimensions"

/**
 * P
 *
 * @param {object} styles Fela styles
 * @param {array} children Children components, usually just text
 *
 * @return {jsx} styled A
 */
const plusIcon = ({ styles, rules, children, href, onClick }) => {
  return <a onClick={onClick} alt={"Download"} href={href} className={styles.styles}>
    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="21.2843" cy="21.2842" r="15.0503" transform="rotate(-90 21.2843 21.2842)" fill="white"/>
      <path d="M20.2201 26.6052L20.2201 21.0868L20.2201 15.5684L22.7284 15.5684L22.7284 21.0868L22.7284 26.6052L20.2201 26.6052Z" fill="#302739"/>
      <path d="M15.7984 19.6753L21.3169 19.6753L26.8353 19.6753L26.8353 22.1837L21.3169 22.1837L15.7984 22.1837L15.7984 19.6753Z" fill="#302739"/>
    </svg>
  </a>
}

/* Styles */
const styles = (props) => ({
  ...fluidDimensions({width: [36, 60], height: [36, 60]}),
  '& svg': {
    width: '100%',
    /* onHover: {
     *   fill: colors.colorConsole500,
     * } */
  },
  /* onHover:{
   *   '& circle': {
   *     stroke: '#FFFFFF'
   *   },
   *   '& path': {
   *     fill: '#FFFFFF',
   *   },
   *   '& line': {
   *     stroke: '#FFFFFF'
   *   },
   * } */
})

/* Component */
const PlusIcon = connectFela({styles})(plusIcon)
export default PlusIcon
