/**
 * @fileOverview H3 component
 * @name H3.js
 */

import React from "react"
import { connect as connectFela } from "react-fela"

import fluidValues from "utilities/fluidValues"
import fluidFonts from "utilities/fluidFonts"
import { fontFamilySansSerif } from "gatsby-theme-fela/fonts"

/**
 * Home page Header H3
 *
 * @param {object} styles Fela styles
 * @param {array} children Children components, usually just text
 *
 * @return {jsx} styled H3
 */
const h3 = ({tag = 'h3', rules, styles, children}) => (
  React.createElement(tag, {className: styles.styles}, children)
)

/* Styles */
const styles = (props) => {
  switch (props.style) {
    default:
      return {
        ...fluidFonts({fontSize: [24, 36], lineHeight: [28, 40]}),
        extend: [{
          condition: !props.noMargin,
          style: {
            ...fluidValues({marginBottom: [24, 32]})
          }
        },{
          condition: props.noMargin,
          style: {
            marginBottom: 0,
          }
        }],
        fontFamily: fontFamilySansSerif,
        fontWeight: 700,
        letterSpacing: 0,
        marginTop: 0,
        position: 'relative',
        whiteSpace: 'break-spaces',
      }
  }
}

/* Component */
export default connectFela({styles})(h3)
