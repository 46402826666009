import React, { useState, useRef } from "react"
import { graphql } from "gatsby"
import sanityLinkFrag from "fragments/sanityLinkFrag"
import { connect as connectFela } from "react-fela"
import { useSpring, animated, config} from 'react-spring'

import Button from 'components/Button'
import { CloseButton } from "components/logos"
import Container from "components/container"
import H1 from "components/typography/H1"
import H3 from "components/typography/H3"
import H4 from "components/typography/H4"
import H5 from "components/typography/H5"
import P from "components/typography/P"
import PlusIcon from "components/PlusIcon"
import UnderlinedTitle from "components/UnderlinedTitle"
import colors from "theme/colors"
import fluidFonts from "utilities/fluidFonts"
import fluidValues from "utilities/fluidValues"
import { fontFamilySansSerif } from "gatsby-theme-fela/fonts"

const hideOnZeroOpacity =  opacity => opacity === 0 ? 'hidden': 'visible'

const Modal = ({ rules, styles, opened, close, children }) => {
  const modalRef = useRef()

  const animatedProps = useSpring({
    initial: {opacity: 0, visibility: 'hidden' },
    from: { opacity: 0, visibility: 'hidden' },
    to: { opacity: opened ? 1 : 0 },
    // config: {...config.default, velocity: 20, mass: 1},
  })

  return (<animated.div style={{...animatedProps, visibility: animatedProps.opacity.interpolate(hideOnZeroOpacity)}} className={styles.modalOverlay}>
    <div ref={modalRef} className={styles.modal}>
      <div className={styles.modalHeader}>
        <CloseButton inverted small extend={{styles: rules.closeButton}} onClick={close} />
      </div>
      <div className={styles.modalBody}>
        {children}
      </div>
    </div>
  </animated.div>)
}

const styles = () => ({
  modalOverlay: {
    display: 'flex',
    minWidth: 80,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    backgroundColor: 'rgba(193,188,193,0.9)',
    zIndex: 3000,
  },
  modal: {
    position: 'relative',
    height: 'auto',
    margin: '0 auto',
    phoneOnly: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
    ...fluidValues({
      paddingRight: [0, 157],
      paddingLeft: [0, 157]
    }),
  },
  modalHeader: {
    minWidth: 110,
    position: 'relative',
    boxSizing: 'border-box',
    backgroundColor: colors.colorConsole500,
    color: '#FFFFFF',
    '@media(max-width: 768px)': {
      marginLeft: '0 !important',
      marginRight: '0 !important',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    ...fluidValues({
      marginBottom: [10, 32],
      paddingTop: [12, 24],
      paddingBottom: [12, 24],
      paddingRight: [12, 24]
    }),
  },
  modalBody: {
    position: 'relative',
    boxSizing: 'border-box',
    backgroundColor: colors.colorConsole500,
    color: '#FFFFFF',
    '@media(max-width: 768px)': {
      marginLeft: '0 !important',
    },
  },
})

export default connectFela(styles)(Modal)
